import React from "react";
import './Contact.css';
import p5 from "p5";

class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    Sketch = (p) => {

        let phase = 0;
        let noiseMax = 0.4;
        let numShapes = 10;
        let transX = this.props.width * .5;
        let transY = this.props.height * .45;

        let shapeLimit;
        if (this.props.width < this.props.height) {
            shapeLimit = this.props.width * 0.7;
        } else {
            shapeLimit = this.props.height * 0.7;
        }

        let greetIndex, currentGreet;
        const greetings = ['CONTACT US', 'CONTÁCTANOS', 'CONTATTACI', 'KONTAKTIERE UNS', 'NOUS CONTACTER', 'NEEM CONTACT OP'];
        const contactInfo = this.props.contactInfo;

        p.setup = (canvasParentRef) => {
            p.createCanvas(this.props.width, this.props.height).parent(canvasParentRef);
            greetIndex = setInterval(() => { if (greetIndex >= greetings.length - 1) { greetIndex = 0 } else { greetIndex += 1 } return greetIndex; }, 1000);
            currentGreet = greetings[greetIndex]
            if (currentGreet === undefined) { currentGreet = greetings[0] }
        }

        p.windowResized = () => {
            p.resizeCanvas(this.props.width, this.props.height);
        }

        p.draw = () => {
            let shapeSize = shapeLimit;
            p.drawingContext.shadowColor = "#FFFFFF";
            p.drawingContext.shadowBlur = 100;

            if (currentGreet !== undefined) { currentGreet = greetings[greetIndex]; } else {
                currentGreet = greetings[p.round(p.random(greetings.length-1))];
            }

            p.clear();
            p.translate(transX, transY);
            p.noStroke();

            let alpha = 0;
            for (let shapes = 0; shapes <= numShapes; shapes++) {
                p.fill(255, 255, 255, alpha);
                p.beginShape();
                for (let a = 0; a < p.TWO_PI; a += p.radians(2)) {
                    let xoff = p.map(p.cos(a + phase), -1, 1, 0, noiseMax);
                    let yoff = p.map(p.sin(a + phase), -1, 1, 0, noiseMax);
                    let r = p.map(p.noise(xoff, yoff), 0, 1, 80, shapeSize);
                    let x = r * p.cos(a);
                    let y = r * p.sin(a);
                    p.vertex(x, y);
                }
                p.endShape(p.CLOSE);
                shapeSize -= p.height * 0.01;
                alpha += 255 / numShapes;
            }
            phase += 0.002;
            p.textSize(p.width / 40);
            if (p.width / 40 < 20) {
                p.textSize(20);
            }
            p.fill(0);
            p.textAlign(p.CENTER, p.CENTER);
            p.textFont('input-mono');
            p.textStyle(p.BOLD);
            p.text(currentGreet, 0, -60);
            p.textStyle(p.ITALIC);
            p.text(contactInfo, 0, 0);

            if (transX !== this.props.width * .5) {
                transX = this.props.width * .5;
            }

            if (transY !== this.props.height * .4) {
                transY = this.props.height * .4;
            }
            //p.print('Contact');
        }

        p.mouseDragged = () => {
            if (p.mouseX > p.width * .1 && p.mouseX < p.width * .9) {
                if (p.mouseY > p.height * .1 && p.mouseY < p.height * .9) {
                    transX = p.mouseX;
                    transY = p.mouseY;
                }
            }
        }

    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current);
    }
    
    componentWillUnmount() {
        this.myP5.remove();
    }

    render() { return (
        <div className="section-container">
            <div className="contact-sketch " ref={this.myRef}></div>
            <a href={'mailto:' + this.props.contactInfo} target="blank">
                <button className="contactUs" type="button">{this.props.language.eng ? 'Let\'s work' : 'Colaboremos'}</button>
            </a>
        </div>
    );}
}

export default Contact;