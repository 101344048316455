import React from 'react';
import './About.css';
import p5 from 'p5';
import aUp from './a-up.svg';
import aDown from './a-down.svg';

const toggleInfo = e => {
  const el = e.target.parentNode.parentNode.nextSibling;
  if (e.target.alt === 'down') {
    e.target.alt = 'up';
    e.target.src = aUp;
    el.style.display = 'block';
  } else {
    e.target.alt = 'down';
    e.target.src = aDown;
    el.style.display = 'none';
  }
};

function engAbout() {
  return (
    <div className='about-us'>
      <h5>
        We are a creative lab dedicated to{' '}
        <span className='black'>
          the production, experimentation, and visual exposure;
        </span>{' '}
        founded in Mexico, by{' '}
        <a
          href='https://www.linkedin.com/in/eduairet'
          alt='LinkedIn Eduardo'
          target='blank'
        >
          Eduardo Aire
        </a>{' '}
        and{' '}
        <a
          href='https://www.linkedin.com/in/pamela-landgrave-19806ba3'
          alt='LinkedIn Pamela'
          target='blank'
        >
          Pamela Landgrave.
        </a>
      </h5>
      <p>
        A combination between <strong>human and machine</strong> that entwines
        interconnections between{' '}
        <strong>the beauty of design and the power of code.</strong>
      </p>
      <p>
        We are{' '}
        <strong>
          loyal to the disruptive aesthetics, lovers of conscience and friends
          of provocation.
        </strong>
      </p>
      <div className='expandable'>
        <h5>Pamela Landgrave</h5>
        <button className='expand-button'>
          <img
            src={aDown}
            alt='down'
            style={{ color: 'black' }}
            onClick={toggleInfo}
          />
        </button>
      </div>
      <p className='person-info'>
        Pamela is a{' '}
        <strong>designer, visual artist, writer, and observer by trade.</strong>{' '}
        She has a natural instinct to analyze everyday situations in order to
        find a way to improve them.{' '}
        <strong>She takes seriously the need to make words visible,</strong>{' '}
        which is why her ability to conceptualize makes her the{' '}
        <strong>
          ideal collaborator for design projects and creative direction.
        </strong>
      </p>
      <div className='expandable'>
        <h5>Eduardo Aire</h5>
        <button className='expand-button'>
          <img src={aDown} alt='down' onClick={toggleInfo} />
        </button>
      </div>
      <p className='person-info'>
        Also known as EAT, he's a{' '}
        <strong>designer, developer, visual artist, and letter lover.</strong>{' '}
        Eduardo is a tenacious competitor, whose{' '}
        <strong>
          hunger for knowledge maintains his researcher spirit on everything he
          likes.
        </strong>{' '}
        His diverse backgrounds make him the{' '}
        <strong>
          perfect collaborator for projects related to analytical thinking,
          automation, and typography.
        </strong>
      </p>
    </div>
  );
}

function espAbout() {
  return (
    <div className='about-us'>
      <h5>
        Somos un laboratorio creativo dedicado a la{' '}
        <span className='black'>
          producción, experimentación y exposición visual;
        </span>{' '}
        fundado en México, por{' '}
        <a
          href='https://www.linkedin.com/in/eduairet'
          alt='LinkedIn Eduardo'
          target='blank'
        >
          Eduardo Aire
        </a>{' '}
        y{' '}
        <a
          href='https://www.linkedin.com/in/pamela-landgrave-19806ba3'
          alt='LinkedIn Pamela'
          target='blank'
        >
          Pamela Landgrave.
        </a>
      </h5>
      <p>
        Una combinación entre <strong>humano y máquina</strong> que teje
        interconexiones entre la{' '}
        <strong>belleza del diseño y la potencia del código.</strong>
      </p>
      <p>
        Somos{' '}
        <strong>
          fieles de la estética disruptiva, amantes de la conciencia y amigos de
          la provocación.
        </strong>
      </p>
      <div className='expandable'>
        <h5>Pamela Landgrave</h5>
        <button className='expand-button'>
          <img src={aDown} alt='down' onClick={toggleInfo} />
        </button>
      </div>
      <p className='person-info'>
        Pamela es una{' '}
        <strong>
          diseñadora, artista visual, escritora y observadora de oficio.
        </strong>{' '}
        Tiene un instinto natural por analizar los situaciones cotidianas con el
        objetivo de encontrar la manera de mejorarlas.{' '}
        <strong>
          Toma con seriedad la necesidad de hacer las palabras visibles,
        </strong>{' '}
        por lo que su capacidad de conceptualizar la hacen la{' '}
        <strong>
          colaboradora ideal para proyectos diseño y dirección creativa.
        </strong>
      </p>
      <div className='expandable'>
        <h5>Eduardo Aire</h5>
        <button className='expand-button'>
          <img src={aDown} alt='down' onClick={toggleInfo} />
        </button>
      </div>
      <p className='person-info'>
        También conocido como EAT, es un{' '}
        <strong>
          diseñador, desarrollador, artista visual y amante de las letras.
        </strong>{' '}
        Eduardo es un competidor tenaz, cuya{' '}
        <strong>
          hambre por aprender mantiene su espíritu investigador sobre todo lo
          que le atrae.
        </strong>{' '}
        Sus diversos antecedentes lo hacen el{' '}
        <strong>
          perfecto colaborador para proyectos relacionados con el pensamiento
          analítico, la automatización y la tipografía.
        </strong>
      </p>
    </div>
  );
}

class About extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  Sketch = p => {
    let colors = '000000-666666-333333-ffffff'.split('-').map(a => '#' + a);
    let skLang, pg, pg1, pg2, pointSize;
    let titleStyle = [
      ['font', 'input-mono'],
      ['font-weight', '800'],
      ['text-align', 'center'],
    ];

    p.setup = canvasParentRef => {
      p.createCanvas(this.props.width, this.props.height).parent(
        canvasParentRef
      );
      p.frameRate(12);
      if (pg1 !== undefined || pg2 !== undefined) {
        clearSetup();
      }
      pg = p.createDiv();
      pg.style('display', 'flex');
      pg.style('flex-direction', 'column');
      if (p.width > 1500) {
        pg.style('justify-content', 'space-between');
        pg.style('width', `${p.width * 0.8}px`);
      } else {
        pg.style('justify-content', 'space-evenly');
        pg.style('width', `${p.width * 0.95}px`);
      }
      pg.style('height', `${p.height * 0.4}px`);
      pg1 = p.createP();
      pg2 = p.createP();
      pg.child(pg1);
      pg.child(pg2);
      for (let styleEl of titleStyle) {
        pg1.style(styleEl[0], styleEl[1]);
        pg2.style(styleEl[0], styleEl[1]);
      }
    };

    p.windowResized = () => {
      p.resizeCanvas(this.props.width, this.props.height);
    };

    p.draw = () => {
      p.clear();
      p.noStroke();
      for (var x = 0; x < p.width; x += 30) {
        for (var i = 0; i < p.height; i += 30) {
          let c =
            colors[
              p.int(
                i /
                  (p.sin(i) * p.cos(p.frameCount / 40 + p.mouseX / 100) * 5 +
                    40 +
                    p.sin(
                      x / 100 +
                        i / 100 +
                        p.mouseY / 100 +
                        p.noise(p.frameCount / 40, i, x)
                    ) *
                      2) +
                  p.frameCount / 12
              ) % colors.length
            ];
          p.fill(c);
          p.ellipse(x, i, 2);
        }
      }
      pointSize = 12;
      skLang = this.props.language;
      pg1.html(skLang.eng ? 'DEEP' : 'LUZ');
      pg2.html(skLang.eng ? 'LIGHT' : 'PROFUNDA');
      pg1.style('font-size', `${pointSize}px`);
      pg2.style('font-size', `${pointSize}px`);
      while (
        pointSize * pg1.html().length < p.width &&
        pointSize * pg2.html().length < p.width
      ) {
        pg1.style('font-size', `${pointSize}px`);
        pg2.style('font-size', `${pointSize}px`);
        pointSize += 1;
      }
      pg1.style('color', `rgba(255, 255, 255, ${p.random(0.5, 1)}`);
      pg2.style('color', `rgba(255, 255, 255, ${p.random(0.5, 1)}`);
      pg1.style('height', `${pg1.size().height}`);
      pg2.style('height', `${pg2.size().height}`);
      pg1.style('font-weight', `${100 + p.round((p.frameCount * 50) % 700)}`);
      pg2.style('font-weight', `${100 + p.round((p.frameCount * 50) % 700)}`);
      pg.position(
        (p.width - pg.size().width) * 0.5,
        (p.height - pg.size().height) * 0.5
      );
      p.noFill();
      p.stroke(p.random(255 * 0.25, 255 * 0.5));
      p.strokeWeight(4);
      p.triangle(
        p.width * 0.5 - p.width * 0.3 + ranTriangle()[0],
        p.height * 0.2 - ranTriangle()[1],
        p.width * 0.5 + ranTriangle()[0],
        p.height * 0.9 + ranTriangle()[1],
        p.width * 0.5 + p.width * 0.3 + ranTriangle()[0],
        p.height * 0.2 + ranTriangle()[1]
      );
    };

    const ranTriangle = () => {
      const amplitude = 100;
      const negPosValue = p.sin(p.frameCount * 0.5);
      return [
        p.sin(p.noise(p.frameCount)) * amplitude * negPosValue,
        p.cos(p.noise(p.frameCount)) * amplitude * negPosValue,
      ];
    };

    const clearSetup = () => {
      pg1.remove();
      pg2.remove();
    };
  };

  componentDidMount() {
    this.myP5 = new p5(this.Sketch, this.myRef.current);
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.myP5.setup();
    }
  }

  componentWillUnmount() {
    this.myP5.remove();
  }

  render() {
    const us = 'Pamela & Eduardo';

    return (
      <div className='section-container'>
        <div className='about-sketch' ref={this.myRef}></div>
        <div className='we-are'></div>
        <div className='info-container'>
          <div className='info-el'>
            <div className='about-us-photo' alt={us} title={us}></div>
            <p className='us-photo'>
              <span>{'Eduardo & Pamela'}</span>
              <span>
                {this.props.language.eng ? ' Pic by ' : ' Foto por '}
                <a
                  href='https://eigt.myportfolio.com/'
                  alt='eigt portfolio'
                  target='blank'
                >
                  EIGT
                </a>
              </span>
            </p>
          </div>
          {!this.props.language.eng ? espAbout() : engAbout()}
        </div>
      </div>
    );
  }
}

export default About;
