import React from "react";
import './Sketches.css';
import CirclesBg from './CirclesBg';
import SinWave from './SinWave';

class Sketches extends React.Component {

    constructor(props) {
        super(props);
        this.handleBg = this.handleBg.bind(this);
    }    

    handleBg(e){
        e.preventDefault();
        const value = 1;
        this.props.onClick(value);
    }

    render() {

        const bgSketch = () => {
            if (this.props.home) {
                if (this.props.currentBg === 0) {
                    return <CirclesBg width={this.props.width} height={this.props.height} currentBg={this.props.currentBg}/>
                } else if (this.props.currentBg === 1) {
                    return <SinWave width={this.props.width} height={this.props.height} currentBg={this.props.currentBg}/>
                }
            }
        }

        return(
            <div>
                {bgSketch()}
                <h1 className="Logo" style={{display: !this.props.home  ? 'none' : 'block'}}><a href="/" onClick={this.handleBg}>A</a></h1>
            </div>
        );
    }
}

export default Sketches;
