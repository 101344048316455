import {React} from "react";
import './TypeLab2021.css';

import Story from './type@cooper_calligraphy_jam.gif';
import Code from './code.png';
import Roulette from './roulette1.png';

function TypeLab2021(props) {

    const descripcion = () => {
        return (
            <p className="project-paragraph">El <strong>TypeLab</strong> es una de las actividades que ocurre durante <strong>Typographics y es organizada por <a href="http://coopertype.org">Type@Cooper,</a></strong> su naturaleza es efímera e invita a cualquier persona a compartir sus conocimientos y proyectos dentro del mundo del uso y la creación de tipografía. 
            <br/><br/>En la versión de 2021 se optó por un evento en línea de 72 horas continuas, en el cuál participamos como voluntarios creando una historia de Instagram para cada exponente utilizando Python y DrawBot como herramientas de diseño.</p>
        )
    };
    const description = () => {
        return (
            <p className="project-paragraph">The <strong>TypeLab</strong> is on of the activities of <strong>Typographics and is organized by <a href="http://coopertype.org">Type@Cooper,</a></strong> its nature is ephemeral and invites anyone to share their knowledge and projects within the world of type, it's use and creation. 
            <br/><br/>In the 2021 version, a continuous 72-hour online event was chosen, in which we participated as volunteers creating an Instagram story for each presenter using Python and DrawBot as design tools.</p>
        )
    };

    const cierre = () => {
        return (
            <p className="project-paragraph">Otra de las actividades que realizamos fue el desarrollo de un <a href="https://abismozeroteam.github.io/letterjam-roulette/" target="blank">sitio-web</a> para <strong>la ruleta del Lettering Jam,</strong> la cual asigna una palabra y una emoción a cada participante.</p>
        )
    };
    const close = () => {
        return (
            <p className="project-paragraph">Another of the activities we did, was the development of a <a href="https://abismozeroteam.github.io/letterjam-roulette/" target="blank">website</a> for <strong>the Lettering Jam Roullete,</strong> which assigns a word and an emotion to each participant.</p>
        )
    };

    return (
        <div className='project-gallery'>
            <div id="top" className="project-header"></div>
            <h4 className="project-title">{props.projectName}</h4>
            <div className='project-container'>
                {props.language.eng ? description() : descripcion()}
                <div className="code-output">
                    <div className="code-output-item window" role="img" alt="Code" style={{backgroundImage: `url(${Code})`}}/>
                    <div className="code-output-item story " role="img" alt="Story" style={{backgroundImage: `url(${Story})`}}/>
                </div>
                {props.language.eng ? close() : cierre()}
                <img src={Roulette} alt="Website" />
            </div>
        </div>
    );

}

export default TypeLab2021;
