import React, { useState, useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import './Portfolio.css';

// Pojects covers
import AbismoBrandingCover from './Projects/AbismoBranding/AbismoBrandingCover.png';
import PeterhansCover from './Projects/Peterhans/PeterhansCover.jpg';
import Covid19Cover from './Projects/Covid19/Covid19Cover.png';
import Cover36Days from './Projects/36days2021/cover36days.gif';
import CoverTypeLab from './Projects/TypeLab2021/TypeLabLogo.png';
import CoverRock from './Projects/RocksDay/Portada_Sing.svg';
import Donachida from './Projects/Donachida/DonaChida.png';

// Components
import AbismoBranding from './Projects/AbismoBranding/AbismoBranding';
import Peterhans from './Projects/Peterhans/Peterhans';
import Covid19 from './Projects/Covid19/Covid19';
import TypeLab2021 from './Projects/TypeLab2021/TypeLab2021';
import RocksDay from './Projects/RocksDay/RocksDay';

const portfolioRoute = '/portfolio';

const projectsEng = {
  DonaChida: {
    desc: 'Dona Chida',
    cover: Donachida,
    url: 'https://donachida.abismozero.com/',
    bgColor: '#ff00c3',
    col: 1,
  },
  RockPoster: {
    desc: 'Sing With Us!',
    cover: CoverRock,
    url: `${portfolioRoute}/sing-with-us`,
    bgColor: 'red',
    col: 2,
  },
  TypeLab2021: {
    desc: 'TypeLab Stories 2021',
    cover: CoverTypeLab,
    url: `${portfolioRoute}/typelab-stories-2021`,
    bgColor: 'rgb(94, 199, 87)',
    col: 2,
  },
  insta36days2021: {
    desc: '36 Days of Type 2021',
    cover: Cover36Days,
    url: 'https://36daysoftype2021.abismozero.com/',
    bgColor: 'black',
    col: 1,
  },
  covid19: {
    desc: 'COVID-19 in Mexico',
    cover: Covid19Cover,
    url: `${portfolioRoute}/covid19-mexico-data`,
    bgColor: 'red',
    col: 2,
  },
  petterhans: {
    desc: 'Peterhans Roulette',
    cover: PeterhansCover,
    url: `${portfolioRoute}/roulette-peterhans`,
    bgColor: 'white',
    col: 2,
  },
  abismo: {
    desc: 'Our Branding',
    cover: AbismoBrandingCover,
    url: `${portfolioRoute}/abismo-branding`,
    bgColor: 'black',
    col: 1,
  },
};

const projectsEsp = {};
Object.entries(projectsEng).forEach(item => {
  projectsEsp[item[0]] = {};
  Object.keys(item[1]).forEach(key => {
    projectsEsp[item[0]][key] = projectsEng[item[0]][key];
  });
});

projectsEsp.RockPoster.desc = '¡Canta con nosotros!';
projectsEsp.TypeLab2021.desc = 'TypeLab Stories 2021';
projectsEsp.insta36days2021.desc = '36 Days of Type 2021';
projectsEsp.covid19.desc = 'COVID-19 en México';
projectsEsp.petterhans.desc = 'Ruleta Peterhans';
projectsEsp.abismo.desc = 'Nuestra marca';

function Portfolio(props) {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = e => {
    setMousePosition({ x: e.pageX, y: e.pageY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);
    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  function titleTranslate(e) {
    const pr = e.target;
    const el = e.target.firstChild;

    const hmargin = pr.getBoundingClientRect().width * 0.1;
    const vmargin = pr.getBoundingClientRect().height * 0.1;
    const leftMargin = pr.getBoundingClientRect().x + hmargin;
    const rightMargin =
      pr.getBoundingClientRect().x + pr.getBoundingClientRect().width - hmargin;
    const topMargin = pr.getBoundingClientRect().y + window.scrollY + vmargin;
    const bottomMargin =
      pr.getBoundingClientRect().y +
      window.scrollY +
      pr.getBoundingClientRect().height -
      vmargin;

    if (
      mousePosition.x < leftMargin ||
      mousePosition.x > rightMargin ||
      mousePosition.y < topMargin ||
      mousePosition.y > bottomMargin
    ) {
      el.style.display = 'none';
      pr.style.cursor = 'pointer';
    } else {
      pr.style.cursor = 'none';
      el.style.top = `${mousePosition.y}px`;
      el.style.left = `${mousePosition.x}px`;
      el.style.display = 'block';
      el.style.transform = 'translate(-50%, -50%)';
    }
  }

  function titleAppear(e) {
    const el = e.target.firstChild;
    el.style.display = 'block';
  }

  function titleReset(e) {
    const el = e.target.firstChild;
    el.style.display = 'none';
  }

  function updateProject(e) {
    const newPath = e.target.pathname;
    props.updateProject(newPath);
  }

  const galleryItems = projects => {
    const listItems = Object.keys(projects).map(project =>
      projects[project].url.includes(portfolioRoute) ? (
        <Link
          to={projects[project].url}
          key={project}
          className={
            projects[project].col === 2
              ? 'gallery-item'
              : 'gallery-item one-col'
          }
          style={{
            backgroundImage: `url(${projects[project].cover})`,
            backgroundColor: projects[project].bgColor,
          }}
          onMouseOver={titleAppear}
          onMouseMove={titleTranslate}
          onMouseOut={titleReset}
          onClick={updateProject}
        >
          <div className='g-item-title'>{projects[project].desc}</div>
        </Link>
      ) : (
        <a
          href={projects[project].url}
          key={project}
          target='blank'
          className={
            projects[project].col === 2
              ? 'gallery-item'
              : 'gallery-item one-col'
          }
          style={{
            backgroundImage: `url(${projects[project].cover})`,
            backgroundColor: projects[project].bgColor,
          }}
          onMouseOver={titleAppear}
          onMouseMove={titleTranslate}
          onMouseOut={titleReset}
        >
          <div className='g-item-title'>{projects[project].desc}</div>
        </a>
      )
    );
    return listItems;
  };

  const gallery = () => {
    return (
      <div className='gallery'>
        <div id='top' className='gallery-header'></div>
        {galleryItems(props.language.eng ? projectsEng : projectsEsp)}
      </div>
    );
  };

  return (
    <div className='section-container'>
      <Route exact path={portfolioRoute}>
        {gallery()}
      </Route>
      <Route
        exact
        path={
          props.language.eng
            ? projectsEng.RockPoster.url
            : projectsEsp.RockPoster.url
        }
      >
        <RocksDay
          language={props.language}
          projectName={
            props.language.eng
              ? projectsEng.RockPoster.desc
              : projectsEsp.RockPoster.desc
          }
        />
      </Route>
      <Route
        exact
        path={
          props.language.eng
            ? projectsEng.TypeLab2021.url
            : projectsEsp.TypeLab2021.url
        }
      >
        <TypeLab2021
          language={props.language}
          projectName={
            props.language.eng
              ? projectsEng.TypeLab2021.desc
              : projectsEsp.TypeLab2021.desc
          }
        />
      </Route>
      <Route
        exact
        path={
          props.language.eng ? projectsEng.covid19.url : projectsEsp.covid19.url
        }
      >
        <Covid19
          language={props.language}
          projectName={
            props.language.eng
              ? projectsEng.covid19.desc
              : projectsEsp.covid19.desc
          }
        />
      </Route>
      <Route
        exact
        path={
          props.language.eng
            ? projectsEng.petterhans.url
            : projectsEsp.petterhans.url
        }
      >
        <Peterhans
          language={props.language}
          projectName={
            props.language.eng
              ? projectsEng.petterhans.desc
              : projectsEsp.petterhans.desc
          }
        />
      </Route>
      <Route
        exact
        path={
          props.language.eng ? projectsEng.abismo.url : projectsEsp.abismo.url
        }
      >
        <AbismoBranding
          language={props.language}
          projectName={
            props.language.eng
              ? projectsEng.abismo.desc
              : projectsEsp.abismo.desc
          }
        />
      </Route>
    </div>
  );
}

export default Portfolio;
