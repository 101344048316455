import React from 'react';
import { useEffect } from 'react';
import './Footer.css';

const socialNetworks = {
  Instagram: 'https://www.instagram.com/abismozero',
  Facebook: 'https://www.facebook.com/abismozero',
  Twitter: 'https://twitter.com/abismo_zero',
  Behance: 'https://www.behance.net/abismozero',
  GitHub: 'https://github.com/abismozeroteam',
  LinkedIn: 'https://www.linkedin.com/company/abismozero/',
};

function Footer(props) {
  const socialEls = networks => {
    const listItems = Object.keys(networks).map(network => (
      <p className='' key={network}>
        <a href={networks[network]} target='blank'>
          {network}
        </a>
      </p>
    ));
    return listItems;
  };

  useEffect(() => {
    const ft = document.querySelector('footer');
    const docHeight = document.documentElement.scrollHeight;
    const winHeight = window.innerHeight;
    props.home || props.contact
      ? (ft.style.opacity = 1)
      : (ft.style.opacity = 0);
    if (docHeight > winHeight + ft.clientHeight) {
      ft.previousSibling.lastChild.style.marginBottom = `${
        ft.clientHeight * 2
      }px`;
    }
    onscroll = event => {
      if ((winHeight + window.scrollY) / docHeight >= 0.9) {
        ft.style.opacity = 1;
      } else {
        ft.style.opacity = 0;
      }
    };
  });

  return (
    <footer>
      <div className='socialMedia'>{socialEls(socialNetworks)}</div>
      <p className='footInfo'>
        <strong>
          Maracuyá Atelier S.A. de C.V. ©{' '}
          {props.language.eng
            ? new Date().toLocaleDateString('en-MX', {
                month: 'short',
                day: 'numeric',
              })
            : new Date().toLocaleDateString('es-MX', {
                month: 'short',
                day: 'numeric',
              })}{' '}
          {new Date().getFullYear()}
        </strong>
      </p>
    </footer>
  );
}

export default Footer;
