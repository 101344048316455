import React from "react";
import './Sketches.css';
import p5 from "p5";

class SinWave extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef();
    }

    Sketch = (p) => {

        const circSize = 5;
        let x = 0;
        let angle = 0.0;

        p.setup = (canvasParentRef) => {
            p.createCanvas(this.props.width, this.props.height).parent(canvasParentRef);
            p.frameRate(24);
        }

        p.windowResized = () => {
            p.resizeCanvas(this.props.width, this.props.height);
        }

        p.draw = () => {

            p.clear();
            p.translate(0, p.height*.45);
            let fillVar = 255;
            let gradSpeed = -2;
            while (x < p.width) {
                let amplitude = p.height*.25
                let y = p.sin(p.radians(angle)) * amplitude; 
                p.fill(fillVar);
                p.noStroke();
                p.ellipse(x, y, circSize, circSize);
                x += 2;
                angle += 0.5;
                fillVar += gradSpeed;
                if (fillVar > 255 || fillVar < 20) {
                    gradSpeed *= -1
                }
            }
            x = x % p.width;
            //p.print('SinWave')

        }

    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current)
    }

    componentWillUnmount() {
        this.myP5.remove();
    }
    
    render() { return ( <div className="Bg-sketch" ref={this.myRef}></div> );}
}

export default SinWave;