import React from 'react';
import './Services.css';
import p5 from 'p5';

const espServices = {
  intro1: 'Necesidades cotidianas, soluciones sorprendentes.',
  intro2: 'Lo complejo, nunca fue tan fácil.',
  automate: [
    'Automatización',
    'Creamos recursos personalizados y automatizamos tus tareas.',
    [
      'Scripts para InDesign e Illustrator',
      'Scripts y acciones para PDFs',
      'Scripts para diseño y producción tipográfica',
      'Scripts para visualización de datos',
      'Diseños en lote',
    ],
    'mailto: work@abismozero.com?subject=Automatización',
    'AHORRA TIEMPO',
  ],
  design: [
    'Diseño e interacción',
    'Proyectos con vida propia. Lo imaginas, lo hacemos.',
    [
      'Branding',
      'Diseño editorial',
      'Diseño tipográfico',
      'Diseño interactivo',
      'Diseño y desarrollo web',
    ],
    'mailto: work@abismozero.com?subject=Diseño e interacción',
    'AGREGA BELLEZA',
  ],
  lab: [
    'Lab',
    'Ideas libres y audaces.',
    [
      'Diseño y arte generativos',
      'Diseño paramétrico',
      'Experimentación creativa',
    ],
    'mailto: work@abismozero.com?subject=Quiero lo desconocido',
    'CONTRATA LO DESCONOCIDO',
  ],
};
const engServices = {
  intro1: 'Everyday needs, astonishing solutions.',
  intro2: 'Complexity has never been so easy.',
  automate: [
    'Automation',
    'We create personalized resources and automate your tasks.',
    [
      'InDesign and Illustrator scripting',
      "PDFs' scripts and actions",
      'Type design and font production scripting',
      'Data visualization scripting',
      'Batch design',
    ],
    espServices.automate[3].replace('Automatización', 'Automation'),
    'GET TIME',
  ],
  design: [
    'Design & Interaction',
    'Alive projects. You imagine it, we do it.',
    [
      'Branding',
      'Editorial design',
      'Type design',
      'Interactive design',
      'Web design and development',
    ],
    espServices.design[3].replace(
      'Diseño e interacción',
      'Design and Interaction'
    ),
    'ADD BEAUTY',
  ],
  lab: [
    'Lab',
    'Free and Fearless ideas.',
    [
      'Generative art and design',
      'Parametric design',
      'Creative experimentation',
    ],
    espServices.lab[3].replace('Quiero lo desconocido', 'I want the unknown'),
    'BUY THE UNKNOWN',
  ],
};

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  Sketch = p => {
    let margin, radius, wave, speed;

    p.setup = canvasParentRef => {
      p.createCanvas(this.props.width, this.props.height).parent(
        canvasParentRef
      );
      p.frameRate(60);
      p.noStroke();
      margin = p.width * 0.1;
      radius = p.height * 0.4;
      wave = p.width * 0.2;
      speed = 0.02;
      p.textSize(radius * 0.2);
      p.textFont('input-mono');
      p.textStyle(p.BOLD);
      p.textAlign(p.CENTER);
    };

    p.windowResized = () => {
      p.resizeCanvas(this.props.width, this.props.height);
    };

    p.draw = () => {
      p.clear();
      const yval =
        p.height * 0.5 + p.sin(p.frameCount * speed * 2) * margin * 0.2;
      var morph = 10;
      p.push();
      if (p.width > 1000) {
        p.textSize(radius);
        p.textLeading(radius * 0.8);
      } else {
        p.textSize(radius * 0.5);
        p.textLeading(radius * 0.5);
      }
      p.textFont('input-mono');
      p.textStyle(p.BOLD);
      p.textAlign(p.CENTER);
      p.noFill();
      p.stroke(255);
      p.text('EASY\nPOWER', p.width * 0.5, p.height * 0.5);
      p.pop();
      p.push();
      let xval2 =
        p.width * 0.5 - margin * 2 - p.sin(p.frameCount * speed) * wave;
      p.translate(xval2, yval);
      p.push();
      p.blendMode(p.ADD);
      p.fill(0, 0, 255);
      p.ellipse(
        0,
        0,
        radius + p.sin(p.frameCount * speed) * morph,
        radius + p.cos(p.frameCount * speed) * morph
      );
      p.pop();
      p.fill(255);
      p.text(
        this.props.language.eng ? 'ART' : 'ARTE',
        0,
        p.textLeading() * 0.5 - p.textDescent()
      );
      p.pop();
      p.push();
      p.translate(
        p.width * 0.5,
        p.height * 0.5 + p.sin(p.frameCount * 0.025) * 10
      );
      p.push();
      p.blendMode(p.ADD);
      p.fill(0, 255, 0);
      p.ellipse(0, 0, radius, radius);
      p.pop();
      p.fill(255);
      p.text(
        this.props.language.eng ? 'DESIGN' : 'DISEÑO',
        0,
        p.textLeading() * 0.5 - p.textDescent()
      );
      p.pop();
      p.push();
      const xval1 =
        p.width * 0.5 + margin * 2 + p.sin(p.frameCount * speed) * wave;
      p.translate(xval1, yval);
      p.push();
      p.blendMode(p.ADD);
      p.fill(255, 0, 0);
      p.ellipse(
        0,
        0,
        radius + p.sin(p.frameCount * speed) * morph,
        radius + p.cos(p.frameCount * speed) * morph
      );
      p.pop();
      p.fill(255);
      p.text(
        this.props.language.eng ? 'CODE' : 'CÓDIGO',
        0,
        p.textLeading() * 0.5 - p.textDescent()
      );
      p.pop();
      let collapsingCircles = p.dist(xval1, yval, xval2, yval);
      if (collapsingCircles <= radius * 0.2) {
        p.push();
        p.translate(p.width * 0.5, p.height * 0.5);
        p.fill(0);
        p.textSize(radius * 0.2);
        p.textFont('AbismoZeroLogo');
        p.textAlign(p.CENTER);
        p.text('A', 0, p.textLeading() * 0.5 - p.textDescent());
        p.pop();
      }
    };
  };

  catalog = servicesCatalog => {
    const serviceList = services => {
      return (
        <div className='service-list'>
          {services.map(service => {
            return (
              <p className='service' key={service}>
                {service}
              </p>
            );
          })}
        </div>
      );
    };

    return (
      <div className='services-container'>
        <h4>{servicesCatalog.intro1}</h4>
        <h4>{servicesCatalog.intro2}</h4>
        <div className='catalog'>
          <div className='catalog-el'>
            <h5>{servicesCatalog.automate[0]}</h5>
            <p className='quote'>{servicesCatalog.automate[1]}</p>
            {serviceList(servicesCatalog.automate[2])}
            <a href={servicesCatalog.automate[3]} id='automate-contact'>
              <button className='contact-button'>
                {servicesCatalog.automate[4]}
              </button>
            </a>
          </div>
          <div className='catalog-el'>
            <h5>{servicesCatalog.design[0]}</h5>
            <p className='quote'>{servicesCatalog.design[1]}</p>
            {serviceList(servicesCatalog.design[2])}
            <a href={servicesCatalog.design[3]} id='design-contact'>
              <button className='contact-button'>
                {servicesCatalog.design[4]}
              </button>
            </a>
          </div>
          <div className='catalog-el'>
            <h5>{servicesCatalog.lab[0]}</h5>
            <p className='quote'>{servicesCatalog.lab[1]}</p>
            {serviceList(servicesCatalog.lab[2])}
            <a href={servicesCatalog.lab[3]} id='lab-contact'>
              <button className='contact-button'>
                {servicesCatalog.lab[4]}
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.myP5 = new p5(this.Sketch, this.myRef.current);
  }

  componentWillUnmount() {
    this.myP5.remove();
  }

  render() {
    return (
      <div className='section-container'>
        <div className='services-sketch' ref={this.myRef}></div>
        {this.props.language.esp
          ? this.catalog(espServices)
          : this.catalog(engServices)}
      </div>
    );
  }
}

export default Services;
