import React from 'react';
import './App.css';
import NavBar from '../NavBar/NavBar';
import Sketches from '../Sketches/Sketches';
import Portfolio from '../Portfolio/Portfolio';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Services from '../Services/Services';
import NotFound from '../NotFound/NotFound';
import { BrowserRouter, Link, Route, Switch, Redirect } from 'react-router-dom';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgrounds: ['CirclesBg', 'SinWave'],
      currentBg: Math.floor(Math.random() * 2),
      home: document.location.pathname === '/' ? true : false,
      about: document.location.pathname === '/about' ? true : false,
      contact: document.location.pathname === '/contact' ? true : false,
      services: document.location.pathname === '/services' ? true : false,
      portfolio: document.location.pathname === '/portfolio' ? true : false,
      project:
        document.location.pathname.includes('/portfolio') &&
        !['/portfolio', '/portfolio/'].includes(document.location.pathname)
          ? true
          : false,
      eng: navigator.language.includes('es') ? false : true,
      esp: navigator.language.includes('es') ? true : false,
      width: window.innerWidth,
      height: window.innerHeight,
      contactInfo: 'work@abismozero.com',
    };
    this.handleCurrentBg = this.handleCurrentBg.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.handleEngClick = this.handleEngClick.bind(this);
    this.handleEspClick = this.handleEspClick.bind(this);
    this.handleHomeClick = this.handleHomeClick.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.handlePath = this.handlePath.bind(this);
  }

  handleSize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleCurrentBg(value) {
    if (this.state.currentBg < this.state.backgrounds.length - 1) {
      this.setState({ currentBg: this.state.currentBg + value });
    } else {
      this.setState({ currentBg: 0 });
    }
  }

  handleEngClick(e) {
    e.preventDefault();
    this.setState({ eng: true, esp: false });
    const menu = document.getElementById('mobile-menu');
    if (menu !== null) {
      menu.innerHTML = 'MENU';
    }
  }
  handleEspClick(e) {
    e.preventDefault();
    this.setState({ eng: false, esp: true });
    const menu = document.getElementById('mobile-menu');
    if (menu !== null) {
      menu.innerHTML = 'MENÚ';
    }
  }

  handleNavClick(clickOrigin) {
    if (clickOrigin === 'Home') {
      this.setState({
        home: true,
        about: false,
        contact: false,
        portfolio: false,
        project: false,
        services: false,
      });
    } else if (clickOrigin === 'About') {
      this.setState({
        home: false,
        about: true,
        contact: false,
        portfolio: false,
        project: false,
        services: false,
      });
    } else if (clickOrigin === 'Contact') {
      this.setState({
        home: false,
        about: false,
        contact: true,
        portfolio: false,
        project: false,
        services: false,
      });
    } else if (clickOrigin === 'Portfolio') {
      this.setState({
        home: false,
        about: false,
        contact: false,
        portfolio: true,
        project: false,
        services: false,
      });
    } else if (clickOrigin === 'Services') {
      this.setState({
        home: false,
        about: false,
        contact: false,
        portfolio: false,
        project: false,
        services: true,
      });
    }
  }

  handlePath() {
    const cPath = document.location.pathname;
    if (cPath === '/') {
      this.setState({
        home: true,
        about: false,
        contact: false,
        portfolio: false,
        project: false,
        services: false,
      });
    } else if (cPath === '/about') {
      this.setState({
        home: false,
        about: true,
        contact: false,
        portfolio: false,
        project: false,
        services: false,
      });
    } else if (cPath === '/contact') {
      this.setState({
        home: false,
        about: false,
        contact: true,
        portfolio: false,
        project: false,
        services: false,
      });
    } else if (cPath === '/portfolio') {
      this.setState({
        home: false,
        about: false,
        contact: false,
        portfolio: true,
        project: false,
        services: false,
      });
    } else if (
      cPath.includes('/portfolio') &&
      !['/portfolio', '/portfolio/'].includes(cPath)
    ) {
      this.setState({
        home: false,
        about: false,
        contact: false,
        portfolio: false,
        project: true,
        services: false,
      });
    } else if (cPath === '/services') {
      this.setState({
        home: false,
        about: false,
        contact: false,
        portfolio: false,
        project: false,
        services: true,
      });
    }
  }

  handleHomeClick(e) {
    this.setState({
      home: true,
      about: false,
      contact: false,
      portfolio: false,
      project: false,
      services: false,
    });
  }

  updateProject(newPath) {
    if (
      newPath.includes('/portfolio') &&
      !['/portfolio', '/portfolio/'].includes(newPath)
    ) {
      this.setState({
        home: false,
        about: false,
        contact: false,
        portfolio: false,
        project: true,
        services: false,
      });
    }
  }

  componentDidMount() {
    this.handleSize();
    window.addEventListener('resize', this.handleSize);
    window.addEventListener('orientationchange', this.handleSize);
    window.addEventListener('hashchange', this.handlePath);
    window.addEventListener('popstate', this.handlePath);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSize);
    window.removeEventListener('orientationchange', this.handleSize);
    //window.removeEventListener('hashchange', this.handlePath);
    //window.removeEventListener('popstate', this.handlePath);
  }

  render() {
    const language = { eng: this.state.eng, esp: this.state.esp };

    const liActive = {
      color: 'black',
      backgroundColor: 'white',
      fontWeight: 'bold',
    };

    const menumobile = () => {
      const menu = document.getElementById('mobile-menu');
      if (menu !== null) {
        menu.innerHTML = this.state.eng ? 'MENU' : 'MENÚ';
      }
    };

    return (
      <BrowserRouter>
        <div className='App'>
          <header className='App-header'>
            <NavBar
              width={this.state.width}
              language={language}
              onClick={this.handleNavClick}
              onhashchange={this.handleNavClick}
              home={this.state.home}
              portfolio={this.state.portfolio}
              about={this.state.about}
              contact={this.state.contact}
              services={this.state.services}
            />
            <Link
              className='homeLogo'
              style={{ display: !this.state.home ? 'block' : 'none' }}
              title='Home'
              to='/'
              onClick={this.handleHomeClick}
            >
              A
            </Link>

            <table className='lang'>
              <tbody>
                <tr>
                  <th id='lang-title'>
                    {language.eng ? 'Language' : 'Idioma'}
                  </th>
                </tr>
                <tr>
                  <td
                    style={!language.eng ? {} : liActive}
                    title='English'
                    alt='English'
                    onClick={this.handleEngClick}
                  >
                    {language.eng ? 'Eng' : 'Ing'}
                  </td>
                  <td
                    style={!language.esp ? {} : liActive}
                    title='Español'
                    alt='Español'
                    onClick={this.handleEspClick}
                  >
                    {language.eng ? 'Spa' : 'Esp'}
                  </td>
                </tr>
              </tbody>
            </table>
          </header>

          <Sketches
            onClick={this.handleCurrentBg}
            currentBg={this.state.currentBg}
            home={this.state.home}
            portfolio={this.state.portfolio}
            about={this.state.about}
            contact={this.state.contact}
            width={this.state.width}
            height={this.state.height}
          />

          <Switch>
            <Route exact path='/' />
            <Route sensitive path='/portfolio'>
              <Portfolio
                width={this.state.width}
                height={this.state.height}
                portfolio={this.state.portfolio}
                project={this.state.project}
                updateProject={this.updateProject}
                language={language}
              />
            </Route>
            <Route sensitive exact path='/services'>
              <Services
                width={this.state.width}
                height={this.state.height}
                services={this.state.services}
                language={language}
              />
            </Route>
            <Route sensitive exact path='/about'>
              <About
                width={this.state.width}
                height={this.state.height}
                about={this.state.about}
                language={language}
              />
            </Route>
            <Route sensitive exact path='/contact'>
              <Contact
                width={this.state.width}
                height={this.state.height}
                contact={this.state.contact}
                language={language}
                contactInfo={this.state.contactInfo}
              />
            </Route>
            <Route sensitive exact path='/not-found'>
              <NotFound language={language} />
            </Route>

            <Route path='*'>
              <Redirect to='/not-found' />
            </Route>
          </Switch>

          <Footer
            width={this.state.width}
            home={this.state.home}
            about={this.state.about}
            contact={this.state.contact}
            portfolio={this.state.portfolio}
            project={this.state.project}
            language={language}
          />
          {menumobile()}
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
