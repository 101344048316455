import {React, useEffect} from "react";
import './Covid19.css';
import Covid1901 from './Covid1901.gif';
import Covid1902 from './Covid1902.png';
import CovidTermo from './CovidTermo.svg';
import Covid19Cover from './Covid19Cover.png';
import headGraphic1 from './COVID-HeadGraphic.svg';
import headGraphic2 from './COVID-HeadGraphicB.svg';

const descriptionEng = 'Poster series that narrates a year of COVID-19 in Mexico. Analyzing each of the states of the country, from 2020/03/02 to 2021/03/02. Using visual resources we made a timeline, where the white dots represent the confirmed cases and the black dots represent the percentage of deaths over them.';
const descriptionEsp = 'Serie de carteles que narra un año de COVID-19 en México. Analizando cada uno de los estados del país, desde el 02/03/2020 hasta el 02/03/2021. Utilizando recursos visuales hicimos una línea de tiempo, donde los puntos blancos representan los casos confirmados y los puntos negros representan el porcentaje de muertes sobre ellos.';

const descriptionEsp2 = 'Asignamos el color de fondo por estado de acuerdo con la siguiente operación.\nTotal histórico de muertes ÷ Total histórico de casos confirmados.';
const descriptionEng2 = 'We assigned the background color by state according to the following operation.\nHistorical total of deaths ÷ Historical total of confirmed cases.';

const captionEsp = 'El porcentaje más bajo es de Baja California Sur (4.5%) y el más alto es de Baja California (16.5%).';
const captionEng = 'The lowest percentage is from Baja California Sur (4.5%), and the highest is from Baja California (16.5%).';

function Covid19(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='project-gallery'>
            <div id="top" className="project-header"></div>
            <h4 className="project-title">{props.projectName}</h4>
            <div className="headGraphic">
                <img src={headGraphic2} alt='Decoration'/>
                <img src={headGraphic1} alt='Decoration'/>
            </div>
            <div className="covid-description">
                <div className="COVID19-poster" style={{backgroundImage: `url(${Covid1901})`}}></div>
                <h5>{props.language.eng ? 'A Year in Poster Series' : 'Un año en carteles'}</h5>
                <p>{props.language.eng ? descriptionEng : descriptionEsp}</p>
            </div>
            <div className="COVID19-main-image" style={{backgroundImage: `url(${Covid1902})`}}></div>
            <div className="covid-description">
                <p>{props.language.eng ? descriptionEng2 : descriptionEsp2}</p>
                <img src={CovidTermo} alt={props.language.eng ? "Deaths by cases" : "Muertes entre casos"}/>
                <figcaption>{props.language.eng ? captionEng : captionEsp}</figcaption>
            </div>
            <img className="COVID19-close-image" alt={props.language.eng ? "Stack of posters" : "Pila de carteles"} src={Covid19Cover}/>
            <p className="reference">{props.language.eng ? 'Data from: ' : 'Datos de: '} <a href="https://datos.covid-19.conacyt.mx/" target="blank">datos.covid-19.conacyt.mx</a></p>
        </div>
    );

}

export default Covid19;