import React from "react";
import './Sketches.css';
import p5 from "p5";

class CirclesBg extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    Sketch = (p) => {

        p.setup = (canvasParentRef) => {
            p.createCanvas(this.props.width, this.props.height).parent(canvasParentRef);
            p.frameRate(18);
        }

        p.windowResized = () => {
            p.resizeCanvas(this.props.width, this.props.height);
        }

        p.draw = () => {

            p.clear();
            let circles = p.random(200);
            let circSize = p.random(2, 1000);
            p.noFill();
            p.stroke(255);
            p.strokeWeight(p.random(0.1, 0.5));
            for(let i = 0; i < circles; i++) {
                p.ellipse(p.width*.5, p.height*.5, circSize*1, circSize*1);
            }
            //p.print('Circles');

        }

    }

    componentDidMount() {
        this.myP5 = new p5(this.Sketch, this.myRef.current);
    }

    componentWillUnmount() {
        this.myP5.remove();
    }
    
    render() { return ( <div className="Bg-sketch" ref={this.myRef}></div> );}

}

export default CirclesBg;