function NotFound(props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className='section-container'
    >
      <h2 className='not-found'>
        {props.language.eng ? 'Not found!' : 'No encontrado'}
      </h2>
    </div>
  );
}

export default NotFound;
