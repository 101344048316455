import React from "react";
import './NavBar.css';

import { Link } from 'react-router-dom';

const sections = {
    'Portfolio': '/portfolio',
    'About': '/about',
    'Contact': '/contact',
    'Services': '/services',
  };

const mobileQuery = 1000;

const menuLanguage = (width, menuHandler, lang) => {
    return <h6 id="mobile-menu" className="mobileMenu" style={{display: width <= mobileQuery ? 'block' : 'none'}} onClick={menuHandler}>{lang.eng ? 'MENU' : 'MENÚ'}</h6>;
}

class NavBar extends React.Component {

    constructor(props){
        super(props);
        this.state = { mobileMenuClicked: false };
        this.handleHome = this.handleHome.bind(this);
        this.handlePortfolio = this.handlePortfolio.bind(this);
        this.handleAbout = this.handleAbout.bind(this);
        this.handleContact = this.handleContact.bind(this);
        this.handleServices = this.handleServices.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
    }

    handlePortfolio(e) {
        const value = Object.keys(sections)[0];
        this.props.onClick(value);
        if (this.props.width <= mobileQuery ){
            this.setState({mobileMenuClicked: !this.state.mobileMenuClicked});
        }
    }

    handleAbout(e) {
        const value = Object.keys(sections)[1];
        this.props.onClick(value);
        if (this.props.width <= mobileQuery ){
            this.setState({mobileMenuClicked: !this.state.mobileMenuClicked});
        }
    }
    
    handleContact(e) {
        const value = Object.keys(sections)[2];
        this.props.onClick(value);
        if (this.props.width <= mobileQuery ){
            this.setState({mobileMenuClicked: !this.state.mobileMenuClicked});  
        }
    }
    
    handleServices(e) {
        const value = Object.keys(sections)[3];
        this.props.onClick(value);
        if (this.props.width <= mobileQuery ){
            this.setState({mobileMenuClicked: !this.state.mobileMenuClicked});
        }
    }

    handleHome(e) {
        const value = 'Home';
        this.props.onClick(value);
        if (this.props.width <= mobileQuery ){
            this.setState({mobileMenuClicked: false});  
        }
    }

    handleMenu(e) {
        e.preventDefault();
        if (this.props.width <= mobileQuery ){
            this.setState({mobileMenuClicked: !this.state.mobileMenuClicked});
        }   
    }

    render() {

        const navItemClass = this.props.width > mobileQuery ? 'nav-items' : 'nav-items-mobile';
        const navItemClassActive = this.props.width > mobileQuery ? 'nav-items-active' : 'nav-items-active-mobile';

        return(
            <nav >
                {menuLanguage(this.props.width, this.handleMenu, this.props.language)}
                
                <Link to={this.props.portfolio ? '/' : Object.values(sections)[0]}
                className={!this.props.portfolio ? navItemClass : navItemClassActive}
                style={{display: this.state.mobileMenuClicked || navItemClass === 'nav-items' ? 'block' : 'none'}}
                onClick={this.props.portfolio && document.location.pathname === Object.values(sections)[0] ? this.handleHome : this.handlePortfolio}>
                    {this.props.language.eng ? Object.keys(sections)[0] : 'Portafolio'}
                </Link>

                <Link to={this.props.services ? '/' : Object.values(sections)[3]}
                className={!this.props.services ? navItemClass : navItemClassActive}
                style={{display: this.state.mobileMenuClicked || navItemClass === 'nav-items' ? 'block' : 'none'}}
                onClick={this.props.services ? this.handleHome : this.handleServices}>
                    {this.props.language.eng ? Object.keys(sections)[3] : 'Servicios'}
                </Link>

                <a className={navItemClass} target="blank"
                style={{display: this.state.mobileMenuClicked || navItemClass === 'nav-items' ? 'block' : 'none'}}
                href="https://society6.com/abismozero" id="abismo-merch-link">
                    {this.props.language.eng ? 'Merch' : 'Mercancía'}
                </a>

                <a className={navItemClass} target="blank"
                style={{display: this.state.mobileMenuClicked || navItemClass === 'nav-items' ? 'block' : 'none'}}
                href="https://rarible.com/abismozero" id="abismo-nft-link">NFT's</a>

                <Link to={this.props.about ? '/' : Object.values(sections)[1]}
                className={!this.props.about ? navItemClass : navItemClassActive}
                style={{display: this.state.mobileMenuClicked || navItemClass === 'nav-items' ? 'block' : 'none'}}
                onClick={this.props.about ? this.handleHome : this.handleAbout}>
                    {this.props.language.eng ? Object.keys(sections)[1] : 'Nosotros'}
                </Link>

                <Link to={this.props.contact ? '/' : Object.values(sections)[2]}
                className={!this.props.contact ? navItemClass: navItemClassActive}
                style={{display: this.state.mobileMenuClicked || navItemClass === 'nav-items' ? 'block' : 'none'}}
                onClick={this.props.contact ? this.handleHome : this.handleContact}>
                    {this.props.language.eng ? Object.keys(sections)[2] : 'Contacto'}
                </Link>
            </nav>
        )
    }

}

export default NavBar;