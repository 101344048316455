import {React, useEffect, useState} from "react";
import './AbismoBranding.css';

const descriptionEng = 'Play with us so we can get cool with each other';
const descriptionEsp = 'Juega con nosotros para que entremos en confianza';

function introEsp(){
    return(
        <p>En este laboratorio queremos desafiar las posibilidades. Nos concentramos en imaginar abanicos de soluciones, por lo que construimos una identidad que muta conforme a las necesidades que plantea cada uno de nuestros proyectos.<br/>
        <br/>Somos expertos en adaptarnos a diferentes ecosistemas, queremos que la flexibilidad nos represente y la experimentación nos guíe.<br/>
        <br/>Ser un abismo significa comprender el propio reflejo, desafiando lo visible. Lo mejor del vacío, es que se desconoce en dónde termina, todo puede convertirse en un nuevo proyecto, siendo un final sin fin.</p>
    )
}
function introEng(){
    return (
        <p>In this lab we want to challenge the possibilities. We focus on imagining a range of solutions, so we build an identity that mutates according to the needs of each of our projects.<br/>
        <br/>We are experts in adapting to different ecosystems, we want flexibility to represent us and experimentation to guide us.<br/>
        <br/>Being an abyss means understanding one's own reflection, defying the visible. The best thing about emptiness is that it is unknown where it ends, everything can become a new project, being an endless ending.</p>
    )
}

function AbismoBranding(props) {

    const [weight, setWeight] = useState(100);

    function lerp(val1, val2, factor) {
        return val1 + (val2 - val1) * factor;
    }
    
    function handleWeightChange(weight) {
        return setWeight(document.getElementById('weight-slider').value);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    

    return (
        <div className='project-gallery'>
            <div id="top" className="project-header"></div>
            <h4 className="project-title">{props.projectName}</h4>
            <div className='logo-holder' id='sliderLogo' style={{fontVariationSettings: `"wght" ${weight}`}}>
                <p style={{fontSize: `${lerp(50, 100, window.innerWidth/650)}px`}} className='interactive-logo'>A</p>
                <p className='slider-intro'>{props.language.eng ? descriptionEng : descriptionEsp}</p>
                <div className="var-slider">
                    <input id='weight-slider' className="slider" type="range" min="100" max="800" onChange={handleWeightChange}></input>
                </div>
            </div>
            <div className="abismo-section">
                <div className="abismo-section-section">
                    <div className="tarjetas"></div>
                    <div className="abismo-intro">{props.language.eng ? introEng() : introEsp()}</div>
                    <div className="abismo-renders">
                        <div className="bolsa"></div>
                        <div className="playera"></div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AbismoBranding;