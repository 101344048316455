import {React} from "react";
import './RocksDay.css';
import ComeFlat from './Come1080x1080-02.svg';
import JohnnyFlat from './Johnny1080x1080-01.svg';
import StarFlat from './Star1080x1080-03.svg';
import Come3D from './rockLetterings3.png';
import Johnny3D from './rockLetterings2.png';
import Star3D from './rockLetterings1.png';
import logo from './singWithUsLogo.svg';

function RocksDay(props) {

    const descripcion = () => {
        return (
            <p className="rock-p"><strong>Sing With Us!</strong> es una colección de posters que celebra el <strong>Día Internacional del Rock.</strong>
            <br/>Hicimos una selección de algunas de las canciones más representativas de este género, transformando sus títulos en piezas que mezclan el lettering y el arte 3d, reflejando la esencia de cada época.</p>
        )
    };
    const description = () => {
        return (
            <p className="rock-p"><strong>Sing With Us!</strong> is a collection of posters that celebrate the <strong>International Rock Day.</strong>
            <br/>We made a selection of some of the most representative songs of this genre, transforming their titles into pieces that mix lettering and 3d art, reflecting the essence of each era.</p>
        )
    };

    const cierre = () => {
        return (
            <p className="rock-p"><strong>Sing with us!</strong> Festeja con nosotros el Día Internacional de Rock con nuestros nuevos productos en Society6: <a href="https://society6.com/abismozero" target="blank">@abismozero</a></p>
        )
    };
    const close = () => {
        return (
            <p className="rock-p"><strong>Sing with us!</strong> Celebrate the International Rock Day with our new merch in Society6: <a href="https://society6.com/abismozero" target="blank">@abismozero</a></p>
        )
    };

    return (
        <div className='project-gallery'>
            <div id="top" className="project-header"></div>
            <h4 className="project-title">{props.projectName}</h4>
            <div className="swu-logo" role="img" alt="Logo" style={{backgroundImage: `url(${logo})`}}></div>
            <div className='project-container'>
                {props.language.eng ? description() : descripcion()}
                <div className="three-posters">
                    <div className="poster" role="img" alt="Johnny B. Goode" style={{backgroundImage: `url(${JohnnyFlat})`}}></div>
                    <div className="poster" role="img" alt="Starman" style={{backgroundImage: `url(${StarFlat})`}}></div>
                    <div className="poster" role="img" alt="Come As You Are" style={{backgroundImage: `url(${ComeFlat})`}}></div>
                </div>
                <div className="three-rock-posters">
                    <div className="rockposter" role="img" alt="Johnny B. Goode Poster" style={{backgroundImage: `url(${Johnny3D})`}}></div>
                    <div className="rockposter" role="img" alt="Starman Poster" style={{backgroundImage: `url(${Star3D})`}}></div>
                    <div className="rockposter" role="img" alt="Come As You Are Poster" style={{backgroundImage: `url(${Come3D})`}}></div>
                </div>
                {props.language.eng ? close() : cierre()}
            </div>
        </div>
    );

}

export default RocksDay;