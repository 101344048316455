import {React, useEffect} from "react";
import './Peterhans.css';
import Peterhans01 from './Peterhans01.gif';
import Peterhans02 from './Peterhans02.png';
import PeterhansCover from './PeterhansCover.jpg';

const descriptionEng = 'Posters created for a workshop we gave in the  the Anahuac University. We were inspired by the work of the Bauhaus profesor of Walter Peterhans.';
const descriptionEsp = 'Carteles creados para un taller que impartimos en la Universidad Anáhuac. Nos inspiramos en el trabajo del profesor de Bauhaus de Walter Peterhans.';


function Peterhans(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='project-gallery'>
            <div id="top" className="project-header"></div>
            <h4 className="project-title">{props.projectName}</h4>
            <div className="peterhans-intro">
                <div className="peterhans-posters" style={{backgroundImage: `url(${Peterhans01})`}}></div>
                <p className="peterhans-description">{props.language.eng ? descriptionEng : descriptionEsp}</p>
            </div>
            <div className="petehans-items">
                <div className="peterhans-item" style={{backgroundImage: `url(${Peterhans02})`}}></div>
                <div className="peterhans-item" style={{backgroundImage: `url(${PeterhansCover})`}}></div>
            </div>
        </div>
    );

}

export default Peterhans;